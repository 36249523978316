
import { defineComponent } from "vue";
import MainCoreValuesAnalyzer from "@/components/MainCoreValuesAnalyzer.vue";
// import { id } from "date-fns/locale";
import helpers from "@/helpers/global";

export default defineComponent({
  name: "CoreValuesAnalyzer",
  components: {
    MainCoreValuesAnalyzer,
  },
  computed: {
    companyLogoUrl() {
      const defaultLogoUrl = "";
      const companyLogoUrl: string =
        this.$store.getters["user/companyLogoUrl"] || "";
      const userDataEncoded: any = localStorage.getItem("userData");
      if (userDataEncoded) {
        try {
          const userData = JSON.parse(atob(userDataEncoded) || "{}");
          if (userData.email) {
            return companyLogoUrl;
          } else {
            return defaultLogoUrl;
          }
        } catch (e: any) {
          console.error(e);
        }
      } else {
        return defaultLogoUrl;
      }
      return "";
    },
  },
  data() {
    return {
      // tableData: [],
    };
  },
  async created() {
    // console.log("CoreValuesAnalyzer created");
    await this.$store.dispatch("user/loadCurrentUser", null, { root: true });

    // TODO: Fix visibility issue
    setTimeout(() => {
      document.body.style.visibility = "visible";
    }, 200);

    let locale = "en";
    const lang = this.$router.currentRoute.value.meta.lang;
    if (lang && lang == "TH") {
      locale = "th";
    } else if (lang && lang == "EN") {
      locale = "en";
    }
    (this as any).$i18n.setLocale(locale);
    this.$store.commit("assessment/locale", locale, { root: true });
  },
  methods: {
    getRedirectUrl() {
      const redirectUrl = window.location.href.replace(
        "/core-values-analyzer",
        "/core-values"
      );
      return redirectUrl;
    },
    gotoLoginPage() {
      const user = this.$store.getters["user/user"];
      if (user) {
        const to = helpers.getMyCoreValuesRoute(user);
        this.$router.push(to);
      } else {
        this.$store.commit("auth/redirectUrl", this.getRedirectUrl(), {
          root: true,
        });
        // TODO: use redirectUrl in Login URL (Standard Pattern)
        this.$router.push({ name: "Login" });
      }
    },
    gotoSignupPage() {
      this.$store.commit("auth/redirectUrl", this.getRedirectUrl(), {
        root: true,
      });
      // TODO: use redirectUrl in Login URL (Standard Pattern)
      this.$router.push({ name: "Signup" });
    },
  },
});
